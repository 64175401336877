import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { observable, pipe, throwError } from "rxjs";
import { Observable } from "rxjs";
import { catchError, debounceTime, ignoreElements, map, retry, retryWhen, throttleTime, scan, delay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Capsule } from "../models/orders/capsule.model";
import { ICollectionResponse } from "../models/interfaces/ICollectionResponse.model";
import { IObjectResponse } from "../models/interfaces/IObjectResponse.model";
import { Meeting } from "../models/meetings/meeting.model";
import { ChimeAttendee } from "../models/meetings/chimeAttendee.model";
import { MeetingMuteUnmuteAction } from "../enums/meetingMuteAction.enum";
import { AttendeeSession } from "../models/meetings/attendeeSession.model";
import { LocalOrder } from "../models/orders/localOrder.model";
import { Router } from "@angular/router";
import { ConsoleLogger } from "amazon-chime-sdk-js";
import { ERROR_COMPONENT_TYPE } from "@angular/compiler";

const BASE_URL: string = environment.API_BASE_PATH + '/api/';
var headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')

@Injectable({
        providedIn: "root"
})
export class ApiService {

        constructor(private http: HttpClient) {

        }

        public getCapsules(languageCode: string): Observable<Capsule[]> {
                return this.http.get<ICollectionResponse<Capsule>>(BASE_URL + "catalog/capsules?languagecode=" + languageCode, { headers: headers })
                .pipe(retryWhen(x => this.retryOnError(x, "getCapsules")))
                .pipe(map(res => res.data)).pipe(
                        catchError(this.handleError)
                );
        }

        public getCapsuleProducts(capsuleId: string, languageCode: string): Observable<any> {
                return this.http.get<IObjectResponse<any>>(BASE_URL + "catalog/themes/" + capsuleId + "/" + languageCode, { headers: headers })
                .pipe(retryWhen(x => this.retryOnError(x, "getCapsuleProducts")))
                .pipe(map(res => res.data)).pipe(
                        catchError(this.handleError)
                );
        }

        public getMeeting(meetingGuid: string): Observable<Meeting> {
                return this.http.get<IObjectResponse<Meeting>>(BASE_URL + "meetings/" + meetingGuid, { headers: headers }).pipe(map(res => res.data)).pipe(
                        catchError(this.handle404)
                );
        }

        public getMeetingSession(meetingGuid: string, attendeeGuid: string, meetingToken: string, attendeeToken: string): Promise<IObjectResponse<AttendeeSession>> {
                return this.http.get<IObjectResponse<AttendeeSession>>(BASE_URL + "meetings/" + meetingGuid + "/attendees/" + attendeeGuid + "?meetingToken=" + meetingToken + "&attendeeToken=" + attendeeToken, { headers: headers })
                        .pipe(
                                catchError(this.handleSpecific)
                        )
                        .toPromise();
        }

        public createMeetingAttendee(meetingGuid: string, dto: any): Observable<ChimeAttendee> {
                return this.http.post<IObjectResponse<ChimeAttendee>>(BASE_URL + "meetings/" + meetingGuid + "/attendees", dto, { headers: headers }).pipe(map(res => res.data)).pipe(
                        catchError(this.handleError)
                );
        }

        public createVictoriaMeetingAttendee(meetingGuid: string, dto: any): Observable<ChimeAttendee> {
                return this.http.post<IObjectResponse<ChimeAttendee>>(BASE_URL + "meetings/" + meetingGuid + "/standAlone_attendees", dto, { headers: headers }).pipe(map(res => res.data)).pipe(
                        catchError(this.handleError)
                );
        }

        public connectMeeting(meetingGuid: string, connectToken: string): Observable<string> {
                return this.http.post<IObjectResponse<string>>(BASE_URL + "meetings/" + meetingGuid + "/connect", { MeetingGuid: meetingGuid, ConnectToken: connectToken }, { headers: headers }).pipe(map(res => res.data)).pipe(
                        catchError(this.handle404)
                );
        }

        public openMeeting(meetingGuid: string, meetingToken: string): Observable<ChimeAttendee> {
                headers = headers.set('X-Token', meetingToken);
                return this.http.post<IObjectResponse<ChimeAttendee>>(BASE_URL + "meetings/" + meetingGuid + "/open", {}, { headers: headers }).pipe(map(res => res.data)).pipe(
                        catchError(this.handleError)
                );
        }

        public closeMeeting(meetingGuid: string, meetingToken: string): Observable<boolean> {
                headers = headers.set('X-Token', meetingToken);
                return this.http.post<IObjectResponse<boolean>>(BASE_URL + "meetings/" + meetingGuid + "/close", {}, { headers: headers }).pipe(map(res => res.data)).pipe(
                        catchError(this.handleError)
                );
        }

        public closeMeetingAttendee(meetingGuid: string, attendeeGuid: string, attendeeToken: string) {
                headers = headers.set('X-Token', attendeeToken);
                return this.http.post<any>(BASE_URL + "meetings/" + meetingGuid + "/attendees/" + attendeeGuid + "/close", {}, { headers: headers }).pipe(
                        catchError(this.handleError)
                );
        }

        public changeMuteUnmute(meetingGuid: string, meetingToken: string, muteAction: MeetingMuteUnmuteAction): Observable<any> {
                headers = headers.set('X-Token', meetingToken);
                return this.http.post<any>(BASE_URL + "meetings/" + meetingGuid + "/action", { actionType: muteAction }, { headers: headers }).pipe(
                        catchError(this.handleError)
                );
        }

        public searchCapsule(searchQuery: string, language: string): Observable<any> {
                return this.http.get<IObjectResponse<any>>(BASE_URL + "catalog/capsules/search?Query=" + searchQuery + "&LanguageCode=" + language, { headers: headers }).pipe(throttleTime(2000))
                .pipe(retryWhen(x => this.retryOnError(x, "searchCapsule")))
                .pipe(map(res => res.data)).pipe(
                        catchError(this.handleError)
                );
        }

        public banAllAttendee(meetingGuid: string, meetingToken: string) {
                navigator.sendBeacon(BASE_URL + "meetings/" + meetingGuid + "/attendees/all/ban");
        }

        public banAttendeeById(meetingGuid: string, attendeeGuid: string, meetingToken: string) {
                headers = headers.set('X-Token', meetingToken);
                return this.http.post<any>(BASE_URL + "meetings/" + meetingGuid + "/attendees/" + attendeeGuid + "/ban", {}, { headers: headers }).pipe(
                        catchError(this.handleError)
                );
        }

        public getMollieLink(localOrder: LocalOrder, contextId: number, locale: string) {
                return this.http.post<any>(BASE_URL + "mollie/getLink/" + contextId, { OrderAmount: localOrder.total, Locale: locale, OrderId: localOrder.id ?? "0" }, { headers: headers }).pipe(
                        catchError(this.handleError)
                ).toPromise();
        }

        public getPaymentState(id: string, contextId: number) {
                return this.http.get(BASE_URL + "mollie/getPayment/" + contextId + "?id=" + id, { headers: headers }).pipe(
                        catchError(this.handleError)
                );
        }

        public saveRating(token: string, attendeeGuid: string, Q1: number, Q2: number, Q3: number, Q4: number, comments: string) {
                headers = headers.set('X-Token', token);
                console.log(environment);
                return this.http.post<any>(BASE_URL + "feedback/" + attendeeGuid, { "AppVersion": environment.APP_VERSION, "Q1": Q1, "Q2": Q2, "Q3": Q3, "Q4": Q4, "Comments": comments }, { headers: headers }).pipe(
                        catchError(this.handleError)
                ).toPromise();
        }

        public postMeetingEvents(events: any[]) {
                return this.http.post<any>("https://10wxq36s76.execute-api.eu-west-1.amazonaws.com/prod/meetingevents", JSON.stringify(events), { headers: headers }).pipe(
                        catchError(this.handleError)
                ).toPromise();
        }

        public getClientOrders(meetingGuid: string, meetingToken: string) {
                headers = headers.set('X-Token', meetingToken);
                if(meetingGuid) return this.http.get<any>(BASE_URL + "meetings/" + meetingGuid + "/clientOrders", { headers: headers })
                .pipe(retryWhen(x => this.retryOnError(x, "getClientOrders")))
                .pipe(
                        catchError(this.handleError)
                );

                else{
                        console.log("No Meeting Guid, call aborder");
                        return new Observable<any>();
                }
        }

        public getCatalogueDatas(lang) {
                //headers = headers.set('X-Token',token);
                return this.http.get<any>(BASE_URL + "catalog/search?language=" + lang, { headers: headers })
                .pipe(retryWhen(x => this.retryOnError(x, "getCatalogueDatas")))
                .pipe(
                        catchError(this.handleError)
                ).toPromise();
        }

        public getFilters(lang) {
                //headers = headers.set('X-Token',token);
                return this.http.get<any>(BASE_URL + "catalog/filters?languageCode=" + lang, { headers: headers })
                .pipe(retryWhen(x => this.retryOnError(x, "getFilters")))
                .pipe(
                        catchError(this.handleError)
                ).toPromise();
        }

        public getCatalogueRowDatas(criterias: any, lang: string) {

                return this.http.get<any>(BASE_URL + "catalog/search?language=" + lang + "&pageIndex=" + criterias.index + "&pageSize=36" + criterias.querystring, { headers: headers })
                .pipe(retryWhen(x => this.retryOnError(x, "getCatalogueRowDatas")))
                .pipe(catchError(this.handleError))
                .toPromise();
        }

        public updateCapsuleCustom(code: string[], meetingGuid: string, token: string): Promise<any> {
                headers = headers.set('X-Token', token);
                return this.http.post<any>(BASE_URL + "catalog/capsules/" + meetingGuid, JSON.stringify(code), { headers: headers })
                .pipe(retryWhen(x => this.retryOnError(x, "updateCapsuleCustom")))
                .pipe(
                        catchError(this.handleError)
                ).toPromise();


        }

        public getCapsuleCustom(meetingGuid, languageCode): Observable<Capsule> {
                console.log('API - Getting Custom capsule', languageCode);
                return this.http.get<any>(BASE_URL + "catalog/themes/" + meetingGuid + "/" + languageCode, { headers: headers }).pipe(map(res => res.data))
                .pipe(retryWhen(x => this.retryOnError(x, "getCapsuleCustom")))
                .pipe(
                        catchError(this.handleError)
                );
        }

        private retryOnError(err, method=undefined){
                // 5 retry, 1/2 second delay
                return err.pipe(scan(v => {
                        v++;
                        if (v <= 5) {
                                if(method) console.log(`API - Retry Api Call n° ${v} for method ${method}`);
                                else console.log(`API - Retry Api Call n° ${v}`)
                                return v;
                        }else {
                                if(method && !navigator.onLine) console.log(`API - User Browser is OFFLINE for method call ${method}`);
                                else if(!navigator.onLine) console.log("API - User Browser is OFFLINE");
                                throw err;
                        }
                }, 0)).pipe(delay(1000));   
        }

        //Patch 911 to prevent side effects
        private handle404(error: HttpErrorResponse) {
                //UNAUTHORIZED ERROR || NOT FOUND
                if (error && (error.status == 400 || error.status == 404)) {
                        //force promise rejection in case of unauthorise access
                        //such as try to get meeting without guid aso
                        return new Promise<any>((res, rej) => rej(false));
                } else if (error.error instanceof ErrorEvent) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', error.error.message);
                } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong.
                        console.error(
                                `Backend returned code ${error.status}, ` +
                                `body was: ${error.error}`);
                }

                // Return an observable with a user-facing error message.
                return throwError(
                        error.message);
        }

        //Patch 911 to prevent side effects
        private handleSpecific(error: HttpErrorResponse) {
                //UNAUTHORIZED ERROR
                if (error && (error.status == 405 || error.status == 400)) {
                        //force promise rejection in case of unauthorise access
                        //such as try to get meeting without guid aso
                        return new Promise<any>((res, rej) => rej(false));
                } else if (error.error instanceof ErrorEvent) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', error.error.message);
                } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong.
                        console.error(
                                `Backend returned code ${error.status}, ` +
                                `body was: ${JSON.stringify(error.error)}`);

                }
                // Return an observable with a user-facing error message.
                return throwError(
                        error.message);
        }

        private handleError(error: HttpErrorResponse) {                
                if (error && error.error instanceof ErrorEvent) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', error.error.message);
                } else if(error && error.status && error.error) {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong.
                        console.error(
                                `Backend returned code ${error.status}, ` +
                                `body was: ${JSON.stringify(error.error)}`);

                } else {
                        console.error("Error handled in API Call: HttpErrorResponse is undefined - User might be OFFLINE, no error status, no body info");
                        return throwError({error:{message:"HttpErrorResponse is undefined"}, status:0});
                }
                // Return an observable with a user-facing error message.
                if(error && error.message) return throwError(error.message);
                else if(error) {
                        console.error("Error from error handler  : ", error);
                        return throwError(error);
                }
        }
}