
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ChimeAttendee } from "../models/meetings/chimeAttendee.model";

import { Capsule } from "../models/orders/capsule.model";
import { UserInfo } from "../models/userInfo.model";

import { LanguageCode } from "../models/languageCode.model";
import { LocalOrder } from "../models/orders/localOrder.model";
import { OnAirNavigationState } from "../models/orders/onAirNavigationState.model";
import { LocalStorageService } from "./localStorage.service";
import { Meeting } from "../models/meetings/meeting.model";
import { CountryCode } from "../models/countryCode.model";
import { ApiService } from "./api.service";
import { Delegate } from "../models/delegate.model";
import { TranslateService } from "@ngx-translate/core";
import { ClientOrder } from "../models/orders/clientOrder.model";
import { ClientOrders } from "../models/orders/clientOrders.model";
import * as UAParser from "ua-parser-js";
import { IcuPlaceholder } from "@angular/compiler/src/i18n/i18n_ast";
import * as Sentry from "@sentry/browser";
import { UAService } from "./uaparser.service";
import { ProductImagesType } from "../enums/productImageType.enum";


@Injectable({
    providedIn: 'root'
})

export class StateManager implements OnDestroy {

    //public logMessages: string[];
    //public chatMessages: string[];

    public inversedBackground:string  = null;

    public assetsFavorites: Capsule[];
    public assetsCustom: Capsule;
    public assetsCustomChanged: Subject<boolean>;
    public assetsCustomLight: string[] = [];
    
    public get showAssets() {
        return this.assetsFavorites && this.assetsFavorites.length > 0;
    }

    _delegateOnAirNavigationState : OnAirNavigationState;
    public set delegateOnAirNavigationState(value: OnAirNavigationState){
        if (value)
                this._delegateOnAirNavigationState= {...value};
                else this._delegateOnAirNavigationState= null;
        if (this.isSynchronized) this.clientOnAirNavigationState= value;
    }
    public get delegateOnAirNavigationState() : OnAirNavigationState {
        return this._delegateOnAirNavigationState;
    }


    _clientOnAirNavigationState : OnAirNavigationState;
    public set clientOnAirNavigationState(value: OnAirNavigationState){
        if (value)
                this._clientOnAirNavigationState= {...value};
        else this._clientOnAirNavigationState= null;
    }
    public get clientOnAirNavigationState() : OnAirNavigationState {
        return this._clientOnAirNavigationState;
    }

    public get currentOnAirNavigationState(): OnAirNavigationState {
        if (this.isDelegate) {
                return  this.delegateOnAirNavigationState;
        }else {
                if (this.isSynchronized) {
                        return this.delegateOnAirNavigationState
                } else {
                        return this.clientOnAirNavigationState;
                }
        }

}

    public capsules: Capsule[] ;
    public startOnAir:boolean = false;
    public previousAssetId: string;
    public previousAssetIndex: number = 0;
    
    public isSynchronized: boolean;
    public promptVisible: boolean;
    public mustSaveOrder:boolean;
    
    public currentItemName: string;

    public meetingResponse: any = null;
    public attendeeResponse: any = null;
    public meetingSession: any = null;
    public meetingId: string = null;
    public attendeeId: string = null;
    public hasChimeError: boolean = false;

    public isDelegate: boolean = false;
    public delegateId: string = undefined;

    public contextId: number = undefined;

    public meeting: Meeting = null
    public meetingGuid: string;
    public meetingToken: string;

    public attendeeGuid: string;
    public chimeAttendee: ChimeAttendee;

    public languageCode: string;

    public attendeeToken: string;
    public clientName: string;
    public clientEmail: string;

    public availableLanguageCodes: LanguageCode[];
    public publicLanguageCodes: LanguageCode[];
    public availableCountryCodes: CountryCode[];

    public userVideoDevice:string = null;
    public userAudioDevice:string = null;

    public delegate:Delegate = null;

    public eshopBasePath: string =  "";
    public profilePictureBasePath: string =  "";

    public clientOrders: ClientOrders = new ClientOrders();
    
    public UAResults : UAParser.IResult = null;

    public isSharingActive:boolean = false;


    public get userInfo(): UserInfo {
        return new UserInfo(this.meetingGuid, this.attendeeGuid, (this.isDelegate)? this.meetingToken :  this.attendeeToken);
}

    //public currentOrder: Order;
    public localOrder: LocalOrder;
    public get hasLocalOrder(){
        if(this.localOrder && this.localOrder.orderItems.length>0) return true;
        else return false;
    }


    private destroyed$: Subject<boolean>;

    constructor(private localStorageService: LocalStorageService, private apiService: ApiService , private translate: TranslateService, private parser : UAService) {

        this.destroyed$ = new Subject();
        this.assetsCustomChanged = new Subject();
        this.init();
        
    }


    init() {
        //this.logMessages = [];
        //this.chatMessages = [];
        this.assetsFavorites = [];
        this.isSynchronized = true;
        this.promptVisible = false;

        this.meetingResponse = null;
        this.attendeeResponse = null;
        this.meetingSession = null;
        this.meetingId = null;
        this.attendeeId = null;

        this.isSharingActive = false;



        this.capsules=[];

        if (this.localStorageService.languageCode)
                this.languageCode = this.localStorageService.languageCode;


        this.setContextId(this.localStorageService.context);

        this.attendeeGuid = this.localStorageService.attendeeGuid;
        this.meetingGuid = this.localStorageService.meetingGuid;
        this.meetingToken = this.localStorageService.meetingToken;
        this.clientName = this.localStorageService.clientName;
        this.clientEmail = this.localStorageService.clientEmail;
        this.attendeeToken = this.localStorageService.attendeeToken;
        this.mustSaveOrder = false;

        this.localOrder = new LocalOrder();

        this.UAResults = this.parser.getResult();
        Sentry.setExtra('Device', this.UAResults);
        console.log("STATE - Device detection", JSON.stringify(this.UAResults??""));

        //todo tmp
        if (this.languageCode=='be-fr') {
                this.languageCode='fr_be';
                this.localStorageService.languageCode='fr_be';
        }

        if (this.languageCode=='be-nl') {
                this.languageCode='nl_be';
                this.localStorageService.languageCode='nl_be';
        }

        if (this.languageCode=='fr-fr') {
                this.languageCode='nl_be';
                this.localStorageService.languageCode='nl_be';
        }

        if (this.languageCode=='pt-pt') {
            this.languageCode='pt_pt';
            this.localStorageService.languageCode='pt_pt';
    }

        this.publicLanguageCodes = [
            new LanguageCode("Français", "fr_be"),
            new LanguageCode("Nederlands", "nl_be"),
            new LanguageCode("Deutsche", "de_de"),
            new LanguageCode("Portuges", "pt_pt"),
        ]

       

   }

        public loadClientOrders() {
                if (this.meetingGuid && this.meetingToken)
                        this.apiService.getClientOrders(this.meetingGuid, this.meetingToken).subscribe((res)=> {
                            if(res && res.data) this.clientOrders.setClientOrders(res.data);
                        });
        }

   public setLanguageCode(lang:string){
       this.translate.use(lang);
       this.languageCode = lang;

       this.localStorageService.languageCode = this.languageCode;

       if (this.currentOnAirNavigationState)
                this.loadCapsule( this.currentOnAirNavigationState.asset?.id,  this.currentOnAirNavigationState.index, "reload")
   }

    public setContextId(contextId: number): void {
            if (!contextId) return;
            
        this.contextId = contextId;
        switch (contextId) {
            case 1:
                this.availableLanguageCodes = [
                    new LanguageCode("Français", "fr_fr")
                ];

                this.availableCountryCodes = [
                    new CountryCode("France","fr")
                ]

                this.eshopBasePath = "https://www.victoria-bijoux.fr/myvictoria/";
                this.profilePictureBasePath = "https://intranet.victoria-france.fr/ProfilePictures/";
                break;
            case 2:
                this.availableLanguageCodes = [
                    new LanguageCode("Français", "fr_be"),
                    new LanguageCode("Nederlands", "nl_be")
                ];


                this.availableCountryCodes = [
                    new CountryCode("Belgïe / Belgique","be"),
                    new CountryCode("Nederland","nl"),
                    new CountryCode("Luxembourg","lu")
                ]

                if((this.delegate && this.delegate.isAllowedPortuges) || this.languageCode=="pt_pt"){
                    this.availableLanguageCodes.push(new LanguageCode("Portuges","pt_pt"))
                    this.availableCountryCodes.push(new CountryCode("Portugal","pt"))
                }

                this.eshopBasePath = "https://www.victoria-benelux.com/myvictoria/";
                this.profilePictureBasePath = "https://intranet.victoria-benelux.be/ProfilePictures/";
                break;
            case 3:
                this.availableLanguageCodes = [
                    new LanguageCode("Deutsche", "de_de")
                ];

                this.availableCountryCodes = [
                    new CountryCode("Deutschland","de"),
                    new CountryCode("Österreich","at"),
                ]

                this.eshopBasePath = "https://victoria-schmuck.com/myvictoria/";
                this.profilePictureBasePath = "https://intranet.victoria-deutschland.de/ProfilePictures/";
                break;
            default:
        }

        if (!this.availableLanguageCodes.find(f=> f.languageCode== this.languageCode))
                this.setLanguageCode(this.availableLanguageCodes[0].languageCode);

    }

public getProductImagesPathForThumbs(fileName: string) {
        if(!fileName) fileName = "empty.jpg";
        return "https://d16xcbgaysva9k.cloudfront.net/200x200/" + fileName.toLocaleLowerCase();
       
}

public getProductImagesPathForHigh(fileName: string) {
        if(!fileName) fileName = "empty.jpg";
        return "https://d16xcbgaysva9k.cloudfront.net/600x600/" + fileName.toLocaleLowerCase();
       
}

public getProductImagesPathForAmbiance(fileName: string) {
        if(!fileName) fileName = "empty.jpg";
        return "https://d16xcbgaysva9k.cloudfront.net/1200x1200/" + fileName.toLocaleLowerCase();
       
}

        public loadCapsule(themeId: string, index: number=0, action: string, changeState:boolean = true) : Promise<Capsule> {
                console.log("STATE : LOAD CAPSULE ", themeId);

                const promise= new Promise<Capsule>((resolve, reject)=> {

                        if((action=="update" || action=="force") && this.delegateOnAirNavigationState && this.delegateOnAirNavigationState.asset.id == themeId && this.delegateOnAirNavigationState && this.delegateOnAirNavigationState.asset.languageCode== this.languageCode){
                            if(changeState) this.delegateOnAirNavigationState.index = index;
                                return resolve(this.delegateOnAirNavigationState.asset);
                        } else if (this.delegateOnAirNavigationState && this.delegateOnAirNavigationState.asset.id == themeId && this.delegateOnAirNavigationState.asset.languageCode== this.languageCode) {
                                if(changeState) this.delegateOnAirNavigationState.index = index;
                                return resolve(this.delegateOnAirNavigationState.asset);
                        }
                        else {
                                console.log("STATE : loadCapsule with Langue : " + this.languageCode);
                                let targetIsCustom =  false;
                                let existingCapsule = this.capsules.find(f => f.id == themeId && f.languageCode== this.languageCode);

                                console.log("STATE : Existing Capsules : ", existingCapsule, action);

                                if (existingCapsule && action!="force") {
                                        console.log("STATE : Get Capsule from existing!");
                                        let newNavigationState = new OnAirNavigationState(existingCapsule);
                                        newNavigationState.index = index;
                                        
                                        if(changeState) this.delegateOnAirNavigationState = newNavigationState;

                                        return resolve(existingCapsule);
                                } else {
                                        console.log("STATE : Get Capsule From DB!");
                                        let newCapsule = new Capsule();
                                        newCapsule.isLoading = true;
                                        if(themeId.length>30){
                                            this.apiService.getCapsuleCustom(this.meetingGuid, this.languageCode).subscribe((data) => {
                                                console.log("STATE :  get Capsule Custom : ", data);
                                                newCapsule = { ...data, languageCode: this.languageCode, isActif: false, isLoaded: true, isLoading: false }
        
                                                let newNavigationState = new OnAirNavigationState(newCapsule);
                                                newNavigationState.index = index;
        
                                                if(changeState) this.delegateOnAirNavigationState = newNavigationState;
                                                this.capsules.push(newCapsule);

                                                if(this.clientOnAirNavigationState && this.clientOnAirNavigationState.asset.id==themeId && this.isSynchronized) 
                                                    this.clientOnAirNavigationState.asset = newCapsule;
                                                    

                                                return resolve(newCapsule);;
                                        })
                                        }else{
                                        this.apiService.getCapsuleProducts(themeId, this.languageCode).subscribe((data) => {
                                                console.log("STATE :  get Capsule Regular : ", data);
                                                
                                                newCapsule = { ...data, languageCode: this.languageCode,  isLoaded: true, isLoading: false }
        
                                                let newNavigationState = new OnAirNavigationState(newCapsule);
                                                newNavigationState.index = index;
        
                                                if(changeState) this.delegateOnAirNavigationState = newNavigationState;
                                                this.capsules.push(newCapsule);
                                                return resolve(newCapsule);;
                                        })
                                    }
                                }
                        }
                })
                return promise;
                
        }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    //todo - Check that meetingToken is never resent to clients
    public get UserInfo(): UserInfo {
        return new UserInfo(this.meetingGuid, this.attendeeGuid, this.meetingToken);
    }
}